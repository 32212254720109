import { assign, pick } from 'lodash';

class Task {
  id = null;
  teamID = null;
  userID = null;
  userID_created = null;
  teamID_created = null;
  /*  id: string;
	status: string(queued|working|error|complete);
	type: string(exportCSV|batchUploadPDF|batchGenerate);
	userID: string(userID);
	teamID: string(teamID);
  userID_created: string;
  teamID_created: string;
	created: timestamp;
	updated: timestamp;
	params: object; // This one will vary for each Task.type
  result: object
	error: array[string];*/

  constructor(json) {
    assign(
      this,
      pick(json, [
        'id',
        'status',
        'type',
        'userID',
        'userEmail',
        'teamID',
        'results',
        'created',
        'updated',
        'params',
        'error',
        'userID_created',
        'teamID_created',
      ])
    );
  }

  get json() {
    return pick(this, ['status', 'type', 'userID', 'teamID', 'params', 'error']);
  }
}

export default Task;
